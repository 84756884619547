@tailwind base;
@tailwind components;
@tailwind utilities;

// * {
//   background-color: rgba(255, 55, 0, 0.068);
// }

html {
    font-size: 15px;
}


body {
    margin: 0px;

    // font-size: 14px;
    h2 {
        // font-size: medium;

    }

    // margin: 0;
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //   sans-serif;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
@mixin mBlurBackground {

    background: rgba(255, 255, 255, 0.11);
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(8.2px);
    -webkit-backdrop-filter: blur(10.2px);
    border: 1px solid rgba(255, 255, 255, 1);

}

.blurBackground {
    // backdrop-filter: blur(15px);
    padding: 10px;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    // border-radius: "0.2rem";


    @include mBlurBackground();
    // border: 1px solid rgba(255, 255, 255, 0.59);
    // border: 1px solid rgba(255, 255, 255, 1);

}

div {

    .mapboxgl-popup-content,
    .maplibregl-popup-content {
        // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.35);
        // background-color: transparent;
        @include mBlurBackground();
        padding: 8px;
    }
}

.ItemColorPicker-title {
    position: absolute;
    top: 92px;
    left: 123px;
    padding: 0;
    margin: 40px;
    font-size: 5em;
    font-weight: 400;
    line-height: 0.7em;
    letter-spacing: -2px;
    color: #272730;
}

.ItemColorPicker-palette {
    position: absolute !important;
    top: 120px;
    left: 40px;
    width: 110px !important;
    // width: 50vmin !important;
    height: 110px !important;
    // height: 50vmin !important;
}

.bckground-grad {
    // background-color: rgb(2, 0, 36);
    // background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(77, 77, 77, 1) 35%, rgba(193, 193, 193, 1) 100%);
    background: rgb(28, 28, 28);
    background: linear-gradient(0deg, rgba(28, 28, 28, 1) 0%, rgba(61, 61, 61, 1) 100%);
    background-attachment: fixed;
}

.txt-shadow {
    // text-shadow: 0px 0px 1px rgba(250, 8, 8, 0.9), 0px 0px 20px rgba(249, 32, 32, 0.72);
    // text-shadow: 0px 0px 1px rgba(250, 8, 8, 0.9), 0px 0px 20px rgba(249, 32, 32, 0.147) !important;
    // text-shadow: 0px 0px 1px rgba(250, 8, 8, 0.9) !important;
    // text-shadow: 0px 0px 1px rgb(0, 0, 0), 0px 0px 20px rgba(249, 32, 32, 0.32);
    // text-shadow: 0px 0px 1px rgba(250, 8, 8, 0.9), 0px 0px 20px rgb(126, 15, 15);
    // text-shadow: 0px 0px 1px rgba(240, 240, 240, 0.9), 0px 0px 20px rgba(200, 200, 200, 0.72);
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.961), 0px 0px 20px rgba(0, 0, 0, 0.691) !important;
}

// text-shadow: 0px 0px 1px rgba(250, 8, 8, 0.9), 0px 0px 20px rgba(249, 32, 32, 0.72) !important;

// .someText{
//     font-family: var(--leva-fonts-mono);
// }

.btn-simple-square {
    border-radius: 0px;
    border: 1px solid #333;
    font-weight: normal;
    letter-spacing: 0.05em;
    background-color: #333;
}


// ======= Color Picker ========
.Frm-Pckr {
    .picker {
        width: 300px;
        background: #f0f0f4;
        border-radius: 9px;
    }

    .picker .react-colorful {
        width: auto;
    }

    .picker__swatches {
        display: flex;
        padding: 12px;
        flex-wrap: wrap;
    }

    .picker__swatch {
        width: 24px;
        height: 24px;
        margin: 4px;
        border: none;
        padding: 0;
        border-radius: 4px;
        cursor: pointer;
        outline: none;
    }

    body {
        margin: 0;
        // padding: 50px;
        font: normal 16px/1.4 "PT Mono", monospace;
    }

    input {
        display: block;
        box-sizing: border-box;
        // width: 90px;
        // margin: 20px 55px 0;
        padding: 1px;
        padding-left: 6px;
        border: 1px solid #ddd;
        border-radius: 4px;
        background: #eee;
        outline: none;
        font: inherit;
        // text-transform: uppercase;
    }

    input:focus {
        border-color: #4298ef;
    }

    &.Clr-Pckr {
        input {
            width: 90px;
            text-transform: uppercase;
            text-align: center;
            padding: 1px;

        }
    }

}