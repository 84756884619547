div {
    // background-color: rgba(128, 0, 128, 0.09);
}


.flexCentered {
    display: flex;

    align-items: center;
    justify-content: center;

    // * box-shadow

}

.boxShadow {
    box-shadow: 5px 10px #88888850,
        20px 20px 50px 15px grey;
}
.boxShadow2 {
    box-shadow: 5px 10px #88888850,
        20px 20px 30px 15px grey;
}

.insetText {
    font: bold 200px arial, sans-serif;
    background-color: #565656;
    color: transparent;
    text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.disc {
    transform: scale(1.0, 1.0);
    transition: transform 0.25s;
    transition-timing-function: ease-in-out;
    &:hover {
        transform: scale(0.90, 1.0);
        // transform: scale3d(2.5, 1.2, 0.3);
        // transform: perspective(500px) translate(10px, 0, 20px) rotateY(3deg);

    }
}